import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component{

    render() {
        const FooterMain = styled.div`
            background-color: #111;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
        `

        const SocialIcons = styled.div`
            .social_icon {
                font-size: 20px;
                color: #04e5e5;
                margin: 0 5px;
                cursor: pointer;
                transition: .5s;
                &:hover {
                    color: #00389f;
                }
            }
        `
        const Texto = styled.h2`
      
            font-size: 17px;
            color: #04e5e5;
            margin: 0 5px;
            cursor: pointer;
            transition: .5s;
            &:hover {
                color: #00389f;
            
        }
    `
    const Td = styled.td`
    text-align: center;
`
    
        return (
            <FooterMain>
                <table cellpadding="0" cellspacing="0" border="0">
                    <tr>
                        <Td>
                            <Texto>
                                Ruta 4 6-32 Zona 4, Edificio Granat Guatemala, 01004
                            </Texto>
                        </Td>
                       
                        
                    </tr>
                    <tr>
                    <Td>
                            <Texto>
                                Tel: +502 2375 7765
                            </Texto>
                        </Td>
                    </tr>
                    <tr>
                        <Td>
                        <SocialIcons>
                        <FontAwesomeIcon icon={faFacebook} className="social_icon" onClick={() => window.open('https://www.facebook.com/estrategiayseguridad')} />
                        <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/company/eysgroup/mycompany/?viewAsMember=true')} />
                        <FontAwesomeIcon icon={faInstagram} className="social_icon" onClick={() => window.open('https://www.instagram.com/esconsulting__/')} />
                        <FontAwesomeIcon icon={faYoutube} className="social_icon" onClick={() => window.open('https://www.youtube.com/channel/UCIpK_nkYEcVMp7SqMkDKiEQ')} />
                        <FontAwesomeIcon icon={faTwitter} className="social_icon" onClick={() => window.open('https://twitter.com/esconsulting__')} />
                        
                </SocialIcons>
                        </Td>
                    </tr>
                </table>

            </FooterMain>
        )
    }
}
export default Footer